import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const { basics, warning, psm, camera } = apiPath;

// 重大危险源列表
export const getDangersList = params => {
  return axios.get(`${basics}/app/page/draft/dangers`, { params });
};

// 重大危险源详情
export const getDangerDetail = id => {
  return axios.get(`${basics}/info/draft/dangers/${id}`);
};

// 实时监控
export const getSensorList = params => {
  return axios.get(`${warning}/list/app-sensor`, { params });
};

// 报警感知
export const getAlarmList = params => {
  return Promise.all([
    axios.get(`${warning}/app/page/alarm/records`, { params }),
    axios.get(`${warning}/app/count/alarm/records`, { params })
  ]).then(([res, count]) => {
    return { ...res, total: Number(count) || 0 };
  });
};

// 监控视频
export const getVideoList = params => {
  return axios.get(`${camera}/app/page/videos`, { params });
};

// 查联锁等级列表
export const getInterlockLevels = params => {
  return axios.get(`${warning}/select/chain-level`, { params });
};

// 联锁投切 查回路列表
export const getInterlockList = params => {
  return axios.get(`${warning}/app/page/chain/accounts`, { params });
};

// 查询联锁回路详情
export const getChainAccountsDetail = (id, params) => {
  return axios.get(`${warning}/info/chain/accounts/${id}`, { params });
};

// 获取联锁投切变更记录
export const getInterlockPageChange = params => {
  return axios.get(`${warning}/page/change`, { params });
};

// 联锁投切详情
export const getInterlockDetail = id => {
  return axios.get(`${warning}/info/chain/change/${id}`);
};

// 联锁投切步骤
export const getInterlockRecord = params => {
  return axios.get(`${warning}/info/chain/change/record`, { params });
};

// 安全承诺
export const getSecurityList = params => {
  return axios.get(`${psm}/promise/page/promise/info`, { params });
};
