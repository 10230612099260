<template>
  <div class="interlock-detail">
    <van-nav-bar
      title="联锁投切"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />
    <div v-if="detail.chainAccountVo" class="interlock-info">
      <div class="interlock-title">
        {{ detail.chainAccountVo.chainCircuitName }}
        <span
          class="interlock-status"
          :style="{
            background: statusMap[detail.chainChangeVo.stopStatus].color
          }"
          >{{ statusMap[detail.chainChangeVo.stopStatus].label }}</span
        >
      </div>
      <table class="interlock-body">
        <tr>
          <td class="interlock-label">联锁回路位号：</td>
          <td class="interlock-value">{{ chainCircuitBit }}</td>
        </tr>

        <tr>
          <td class="interlock-label">所属部门：</td>
          <td class="interlock-value">
            {{ detail.chainChangeVo.departmentName }}
          </td>
        </tr>

        <tr>
          <td class="interlock-label">联锁等级：</td>
          <td class="interlock-value">
            {{ detail.chainChangeVo.chainLevelName }}
          </td>
        </tr>

        <tr>
          <td class="interlock-label">投切类型：</td>
          <td class="interlock-value">
            {{ detail.chainChangeVo.changeTypeName }}
          </td>
        </tr>
      </table>
    </div>

    <div class="interlock-steps">
      <van-steps
        active-color="#3278FF"
        direction="vertical"
        :active="record.length"
      >
        <van-step v-for="item in record" :key="item.id">
          <div class="interlock-step-header">
            <div class="interlock-step-title">
              {{ item.operationTypeName }}
              <!-- <span
                class="interlock-step-type"
                :style="{
                  color: getTypeColor(item.operationType),
                  background: getTypeBackgoundColor(item.operationType)
                }"
                >{{ item.operationTypeName }}</span
              > -->
            </div>
            <div class="interlock-step-right">
              申请人： {{ item.operationUserName }}
            </div>
          </div>
          <div class="interlock-step-content">
            计划停用时间：2022-07-28 14
          </div>
          <div class="interlock-step-content">
            计划投用时间：2022-07-28 14
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { getInterlockDetail, getInterlockRecord } from "./api";

const statusMap = {
  0: {
    label: "临时解除",
    color: "#0FB8C5"
  },
  1: {
    label: "长期停用",
    color: "#F6790F"
  },
  2: {
    label: "永久摘除",
    color: "#67C23A"
  }
};

const orangetype = {
  color: "#F68446",
  backgroundColor: "rgba(246, 132, 70, 0.1)"
};

const blueType = {
  color: "#3683F4",
  backgroundColor: "rgba(54, 131, 244, 0.1000)"
};

const greenType = {
  color: "#67C23A",
  backgroundColor: "rgba(103, 194, 58, 0.1000)"
};

const grayType = {
  color: "#9B9B9B",
  backgroundColor: "rgba(155, 155, 155, 0.1000)"
};
const typeMap = {
  "-1": orangetype,
  10: orangetype,
  12: greenType,
  11: grayType
};
export default {
  data() {
    return {
      detail: {},
      record: []
    };
  },
  computed: {
    statusMap: () => statusMap,
    chainCircuitBit() {
      return this.detail.chainChangeVo.chainCircuitBit;
    }
  },
  mounted() {
    const id = this.$route.params.id;
    Promise.all([
      getInterlockDetail(id),
      getInterlockRecord({ chainChangeId: id })
    ]).then(([detail, record]) => {
      this.detail = detail || {};
      this.record = record || [];
    });
  },
  methods: {
    getTypeColor(type) {
      const obj = typeMap[type] || blueType;
      return obj.color;
    },
    getTypeBackgoundColor(type) {
      const obj = typeMap[type] || blueType;
      return obj.backgroundColor;
    }
  }
};
</script>

<style lang="scss" scoped>
.interlock {
  &-info {
    padding: 16px;
    border-bottom: 8px solid #f3f5f8;
  }

  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 22px;
  }

  &-status {
    padding: 0 4px;
    border-radius: 2px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }

  &-body {
    border: none;
    margin-top: 8px;
  }

  &-label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5d5f63;
    line-height: 28px;
  }

  &-value {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #15171b;
    line-height: 28px;
  }

  &-step-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &-step-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 22px;
  }

  &-step-right {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
  }

  &-step-content {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
  }

  &-step-type {
    padding: 2px 5px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f68446;
    line-height: 16px;
    border-radius: 2px;
  }

  &-steps {
    ::v-deep {
      .van-step__circle {
        width: 10px;
        height: 10px;
      }

      .van-steps--vertical {
        padding-left: 51px;
      }

      .van-step--vertical .van-step__circle-container,
      .van-step--vertical .van-step__line {
        left: -21px;
      }
    }
  }
}
</style>
